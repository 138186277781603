<template>
  <div>
    <Breadcrumbs>
      <router-link to="/home/member-info">
        {{ $t("menu.main.membershipInformation") }}
      </router-link>
      >
      {{ $route.meta.title }}
    </Breadcrumbs>

    <div class="form-container pb-15 px-8 pt-7">
      <template v-if="!isSending">
        <div class="text-title --dark">
          {{ $t("memberInfo.introduction") }}
        </div>

        <div class="mt-12 --w-medium">
          {{ $t("memberInfo.enterFriendInfo") }}
        </div>
        <!-- START Sending-form -->
        <v-form ref="form" lazy-validation>
          <label class="--w-medium" for="name"> {{ $t("common.name") }} </label>
          <v-text-field
            id="name"
            :rules="rules.text"
            v-model="name"
            x-large
            solo
            flat
            outlined
            type="text"
            class="mt-2 rounded-lg"
          />

          <label class="--w-medium"  for="hiragana"> {{ $t("common.hiragana") }} </label>
          <v-text-field
            id="hiragana"
            :rules="rules.text"
            v-model="hiragana"
            x-large
            solo
            flat
            outlined
            type="text"
            class="mt-2 rounded-lg"
          />

          <label class="--w-medium"  for="phoneNumber">{{ $t("common.phoneNumber") }}</label>
          <v-text-field
            id="phoneNumber"
            :rules="rules.phoneNumber"
            v-model="phoneNumber"
            placeholder="0001231234"
            x-large
            solo
            flat
            outlined
            type="text"
            class="mt-2 rounded-lg"
          />
        </v-form>
        <!-- END Sending-form -->

        <!-- Sending-button -->
        <div class="text-center">
          <v-btn
            elevation="0"
            @click="submitForm"
            x-large
            rounded
            class="text-heading --white w-100 mt-3"
            color="btn-grad--orange"
          >
            <span class="text-heading font-weight-regular w-100">{{ $t("buttons.sending") }}</span>
            <IconChevronRightCircle color="white" width="23" height="23" class="mr-2"/>
          </v-btn>
        </div>
        <!-- --------------- -->
      </template>

      <!-- ====== If form has been sent ====== -->
      <template v-else>
        <div class="text-title --w-medium --c-prm pb-5">
          {{ $t("memberInfo.thankYouForIntro") }}
        </div>
        <div class="text-title--c-dark">
          {{ $t("memberInfo.thanhYouForIntroDescription") }}
        </div>
        <div class="text-center">
          <v-btn
            elevation="0"
            @click="isSending = false"
            x-large
            rounded
            outlined
            class="bg-white text-heading --prm w-100 mt-5"
          >
            {{ $t("buttons.return") }}
          </v-btn>
        </div>
      </template>
      <!-- =================================== -->
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '../../../components/Breadcrumbs'
import {
  checkPhoneNumber
} from '@/utils/validate'
import IconChevronRightCircle from '../../../components/icons/IconChevronRightCircle'

export default {
  name: 'MemberInfoIntroduction',
  components: {
    IconChevronRightCircle,
    Breadcrumbs
  },
  methods: {
    submitForm () {
      if (this.$refs.form.validate()) {
        setTimeout(() => {
          this.isSending = true
        }, 2000)
      }
    }
  },
  data () {
    return {
      name: null,
      hiragana: null,
      phoneNumber: null,

      isSending: false,
      rules: {
        text: [
          v => !!v || this.$t('rules.isRequired'),
          v => v?.trim().length < 25 || this.$t('rules.invalid')
        ],
        phoneNumber: [
          v => !!v || this.$t('rules.isRequired'),
          v => checkPhoneNumber(v) || this.$t('rules.invalid')
        ]
      }
    }
  }
}
</script>
